// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// Skins
//
// We recommend that all the variables that are staying at the core of your product's styleguide (colors, typography) to be separated in a custom skin
// you can start by using one of the pre-built ones or, simply, create a new one
// learn more about how to create your skin by reading the theme's documentation.
//
// Below you can find 3 pre-defined skins. You can uncomment any one of them and compile Sass using the build tools

// @import "../skins/green/light";
// @import "../skins/green/dark";

// Fonts
//
// uncomment and replace the $font and $font-family-custom-sans-serif value with any URL and font family name from Google Fonts
//

// $font: "https://fonts.googleapis.com/css?family=Nunito:300,400,600,700" !default;
// $font-family-custom-sans-serif: "Nunito", sans-serif !default;
// $font-family-base: $font-family-custom-sans-serif !default;

$font: "https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,600,700" !default;
$font-family-custom-sans-serif: "IBM Plex Sans", sans-serif !default;
$font-family-base: $font-family-custom-sans-serif !default;

// Custom variables
//
// Need additional variables? Simply create it here and make sure you follow the naming formula explained at the top of this file.
